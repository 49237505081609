import Base from './Base'
export default class CacheData extends Base {
  /**
   * cache some list data
   * exp: s
   * @param {*} param0
   */
  constructor ({ data = [], exp = 5 * 3600 }) {
    super()
    this.data = data
    this.exp = exp * 1000
    this.updated = false
    this.timestamp = new Date().getTime()
  }
  get needUpdate () {
    if (!this.updated) return true
    let now = new Date().getTime()
    return now - this.timestamp > this.exp
  }

  updateCache (data) {
    this.data = data
    this.updated = true
    this.timestamp = new Date().getTime()
  }
}
