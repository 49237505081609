import HttpUtil from './http'
import { List, Toast, Lazyload, Dialog } from 'vant'
import UIAppLoading from '../components/UIAppLoading.vue'
import defaultImg from '@/assets/img/default.png'
const Vutil = {}
Vutil.install = function (Vue) {
  // 1. 添加全局方法或属性
  // Vue.myGlobalMethod = function () {
  //   // 逻辑...
  // }

  // 2. 添加全局资源
  Vue.directive('form-view', {
    bind (el) {
      el.addEventListener('click', handleFormView)
    },
    unbind (el) {
      el.removeEventListener('click', handleFormView)
    }
  })

  // 4. 添加实例方法
  Vue.prototype.$http = HttpUtil
  Vue.prototype.$debug = process.env.NODE_ENV === 'development'
  Vue.prototype.$_app_phoneNum = 'tel:13822131711'
  // show or hide loading by func
  const MyLoadingVueCom = Vue.extend(UIAppLoading)
  Vue.prototype.$_app_Loading = {
    instance: null,
    show () {
      if (this.instance) return
      const loadingComponent = new MyLoadingVueCom()
      loadingComponent.mountable = false
      loadingComponent.$mount()
      document.body.appendChild(loadingComponent.$el)
      this.instance = loadingComponent
    },
    hide () {
      if (!this.instance) return
      document.body.removeChild(this.instance.$el)
      this.instance.$destroy()
      this.instance = null
    }
  }
  // vant
  // use this.$toast
  Vue.use(Toast)
  Vue.use(Dialog)
  Vue.use(List)
  Vue.use(Lazyload, {
    loading: defaultImg,
    error: defaultImg
  })
  // app component
}

function handleFormView (event) {
  event.stopPropagation()
  let target = event.target
  // 不处理只读和禁用的元素
  if (target.readonly || target.disabled) {
    return
  }
  // 延时等待键盘弹出
  setTimeout(function () {
    target.scrollIntoViewIfNeeded()
  }, 500)
}

// 滚动记录 activated时还原位置
export const scrollRecordMixin = className => ({
  data () {
    return {
      recordPosition: 0
    }
  },
  methods: {
    scrollRecordMixin_scrollToTop () {
      let $scroll = document.querySelector(className)
      $scroll.scrollTop = 0
    }
  },
  mounted () {
    let $scroll = document.querySelector(className)
    $scroll.addEventListener('scroll', e => {
      this.recordPosition = $scroll.scrollTop
    })
  },
  activated () {
    let $scroll = document.querySelector(className)
    $scroll.scrollTop = this.recordPosition
  }
})

export default Vutil
