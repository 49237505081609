Promise.prototype.finally = function (callback) {
  let P = this.constructor
  return this.then(
    value => P.resolve(callback()).then(() => value),
    reason =>
      P.resolve(callback()).then(() => {
        throw reason
      })
  )
}

// ios :active 伪类不触发，需要在元素或者body上监听touchstart事件
document.body.addEventListener('touchstart', function () {
  // do some thing
})
