<template>
  <div class="home_type_box">
    <div class="home_type_main app_contentBox">
      <swiper v-if="rootTypeList.length>0"
              class="home_type_root"
              :options="swiperOption"
              key="home_type_root">
        <swiper-slide v-for="item of rootTypeList"
                      :key="item.id"
                      class="per"
                      @click.native="nowRootTypeId = item.id"
                      :class="{'active':nowRootTypeId===item.id}">
          {{item.typeName}}
        </swiper-slide>
      </swiper>
      <div class="home_type_content">
        <ui-app-loading v-if="showTypeLoading" />
        <swiper class="home_type_sec"
                :options="swiperOption"
                v-if="nowSecTypeList.length > 0"
                key="home_type_sec">
          <swiper-slide v-for="item of nowSecTypeList"
                        :key="item.id"
                        class="per"
                        @click.native="nowSecTypeId = item.id"
                        v-lazy:background-image="item.icon">
            <span :class="{'active':item.id===nowSecTypeId}">{{item.typeName}}</span>
          </swiper-slide>
        </swiper>
        <div class="home_type_sec_none"
             v-else>暂无分类</div>
        <swiper class="home_type_matter_box"
                :options="swiperOptionMatter"
                v-if="nowMatterListForShow.length > 0"
                key="home_type_matter_box">
          <swiper-slide v-for="(item,index) of nowMatterListForShow"
                        :key="index"
                        class="per_matter_page">
            <div v-for="matter of item"
                 :key="matter.id"
                 class="per_matter"
                 @click="handleMatterClick(matter)">
              <img v-lazy="matter.img">
              <div class="matter_name">
                {{matter.matterName}}
              </div>
              <p>回收最高价 <span>¥{{matter.maxPrice}}</span></p>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev"
               slot="button-prev"></div>
          <div class="swiper-button-next"
               slot="button-next"></div>
        </swiper>
        <div class="home_type_matter_none"
             v-else>暂无物料</div>
      </div>
    </div>
  </div>
</template>

<script>
import VEBus from '../util/VEventBus.js'
import uiAppLoading from '../components/UIAppLoading'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { getMatterList } from '../api'
export default {
  name: 'AppHomeType',
  components: {
    [swiper.name]: swiper,
    [swiperSlide.name]: swiperSlide,
    [uiAppLoading.name]: uiAppLoading
  },
  data () {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        freeMode: true,
      },
      swiperOptionMatter: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      //
      showTypeLoading: true,
      nowRootTypeId: '',
      nowSecTypeId: '',
      //
      nowMatterList: []
    }
  },
  computed: {
    rootTypeList () {
      return this.$store.state.rootTypeList.list
    },
    allTypeList () {
      return this.$store.getters.allTypeList.map((item) => {
        let { id, pid, icon, typeName } = item
        return { id, pid, icon, typeName }
      }) || []
    },
    nowSecTypeList () {
      return this.allTypeList.filter((item) => {
        return item.pid === this.nowRootTypeId
      }) || []
    },
    // 每10个一页
    nowMatterListForShow () {
      let n = 10
      let len = this.nowMatterList.length
      let lineNum = len % n === 0 ? len / n : Math.floor((len / n) + 1)
      let result = []
      for (let i = 0; i < lineNum; i++) {
        let temp = this.nowMatterList.slice(i * n, i * n + n)
        result[i] = [...temp]
      }
      return result
    }
  },
  watch: {
    nowRootTypeId () {
      this.nowSecTypeId = this.nowSecTypeList[0] ? this.nowSecTypeList[0].id : ''
    },
    nowSecTypeId (id) {
      if (id === '') {
        this.nowMatterList = []
      } else {
        this.fetchMatterList()
      }
    }
  },
  methods: {
    async fetchMatterList () {
      this.showTypeLoading = true
      try {
        let list = await getMatterList(this.nowSecTypeId)
        this.nowMatterList = list.map(item => {
          let { id, maxPrice, matterName, titleUrl: img } = item
          return { id, maxPrice, matterName, img }
        })
      } catch (e) {
        console.log(e)
      }
      this.showTypeLoading = false
    },
    handleMatterClick (matter) {
      let { id } = matter
      this.$router.push({ name: 'matter', params: { id } })
    }
  },
  async mounted () {
    this.showTypeLoading = true
    await this.$store.dispatch('getSecTypeAll')
    this.nowRootTypeId = this.rootTypeList[0].id
    this.nowSecTypeId = this.nowSecTypeList[0].id
    this.showTypeLoading = false

    VEBus.$on('APP_SWIPE_TYPE_CLICK', (id) => {
      this.nowRootTypeId = id
    })
  }
}
</script>

<style lang="scss">
.home_type_box {
  background-color: #f6f7fb;

  .home_type_main {
    transform: translateY(50px);
  }

  .home_type_root {
    width: 100%;
    background-color: #fff;
    white-space: nowrap;
    border-bottom: 2px solid $theme-green;
    box-shadow: 0 -5px 10px 0px #eee;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    // overflow: -moz-scrollbars-none;
    overflow: -moz-hidden-unscrollable;
    overflow-x: auto;
    .per {
      cursor: pointer;
      position: relative;
      display: inline-block;
      width: 218px;
      padding: 10px;
      font-size: 18px;
      text-align: center;
      @include text-ellipsis();
      box-sizing: border-box;
      &:not(:last-child) {
        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 60%;
          width: 1px;
          background-color: #ccc;
        }
      }
      &.active {
        background-color: $theme-green;
        color: #fff;
        transform: translateX(-1px);
        &:after {
          content: unset;
        }
      }
    }
  }
  .home_type_content {
    position: relative;
  }
  .home_type_sec {
    height: 95px;
    .per {
      box-sizing: border-box;
      cursor: pointer;
      width: 218px;
      height: 100%;
      padding: 55px 10px 0;
      text-align: center;
      font-size: 18px;
      @include text-ellipsis();
      background-repeat: no-repeat;
      background-position: center 10px;
      background-size: 50px 50px;
      span {
        display: inline-block;
        height: 40px;
        line-height: 35px;
      }
      span.active {
        border-bottom: 3px solid $theme-color;
      }
    }
  }
  .home_type_sec_none {
    height: 95px;
    text-align: center;
    color: #ccc;
    font-size: 18px;
    line-height: 95px;
  }
  .home_type_matter_none {
    height: 275px;
    text-align: center;
    color: #ccc;
    background-color: #fff;
    font-size: 18px;
    line-height: 270px;
    box-shadow: 0 2px 10px 3px #f6f7fb;
  }
  .home_type_matter_box {
    background-color: #fff;
    box-shadow: 0 2px 10px 3px #f6f7fb;
  }
  .per_matter_page {
    padding: 0 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    .per_matter {
      width: 20%;
      font-size: 16px;
      text-align: center;
      padding: 30px 0;
      cursor: pointer;
      img {
        display: inline-block;
        margin: 0 auto;
        width: 110px;
        height: 110px;
        object-fit: contain;
      }
      div {
        padding: 15px 0;
        @include text-ellipsis();
      }
      p {
        @include text-ellipsis();
        span {
          color: $theme-color;
        }
      }
    }
  }
}
</style>
