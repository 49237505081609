<template>
  <div class="home_container">
    <div class="home_swiper_box">
      <div class="home_swiper_menu">
        <div class="per with_qrcode hastri hascursor"
             @click.stop="handleShowQrcode">
          关注领红包
          <span class="tooltip">福利</span>

          <transition name="slide-up-fade">
            <div v-if="showQrcode"
                 class="menu_qrcode_box">
            </div>
          </transition>
        </div>
        <div class="per hascursor">
          <a :href="$_app_phoneNum">人工报价</a>
          <span style="right: -13px;"
                class="tooltip">一键估价</span>
        </div>
        <div class="per hascursor"
             @click="handlePointClick">
          行业痛点
        </div>
        <div class="per hascursor"
             @click="$router.push({name:'about'})">
          关于网萌
        </div>
      </div>
      <div v-if="rootTypeList.length > 0"
           class="home_swiper_rootType">
        <div class="rootType_title">
          回收品类
        </div>
        <div class="rootType_box">
          <div class="per"
               :style="{backgroundImage:`url(${item.icon})`}"
               v-for="item of rootTypeList"
               :key="item.id"
               @click.stop="handleTypeClick(item)">
            {{item.typeName}}
          </div>
        </div>
      </div>
      <swiper v-if="bannerList.length>0"
              class="home_swiper"
              :options="swiperOption">
        <swiper-slide v-for="(item, index) in bannerList"
                      :key="index">
          <a v-if="item.link"
             :href="item.link">
            <img :src="item.img" />
          </a>
          <img v-else
               :src="item.img" />
        </swiper-slide>
      </swiper>
      <div class="home_swiper_bottom app_contentBox">
        <div class="per">
          <div></div>
          <p>1.搜索品类</p>
        </div>
        <div class="per">
          <div></div>
          <p>2.网萌报价</p>
        </div>
        <div class="per">
          <div></div>
          <p>3.预约看货</p>
        </div>
        <div class="per">
          <div></div>
          <p>4.当面回收</p>
        </div>
      </div>
    </div>
    <app-home-type v-if="showType" />
    <app-home-info />
    <app-home-footer />
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import VEBus from '../util/VEventBus.js'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import appHeader from '../components/AppHeader'
import appHomeType from '../components/AppHomeType'
import appHomeInfo from '../components/AppHomeInfo'
import appHomeFooter from '../components/AppHomeFooter'
import { getBannerList } from '../api'
import { eventOnce } from '../util/util.js'
export default {
  name: 'HomeView',
  components: {
    [appHeader.name]: appHeader,
    [appHomeType.name]: appHomeType,
    [appHomeInfo.name]: appHomeInfo,
    [appHomeFooter.name]: appHomeFooter,
    swiper,
    swiperSlide
  },
  data () {
    return {
      swiperOption: {
        // https://github.com/surmon-china/vue-awesome-swiper/issues/410
        autoplay: true,
        loop: true,
      },
      //
      bannerList: [],
      //
      showQrcode: false,
      //
      showType: false
    }
  },
  computed: {
    rootTypeList () {
      return this.$store.state.rootTypeList.list.reduce((arr, item, index) => {
        if (index > 4) return arr
        let { id, icon, typeName } = item
        arr.push({ id, icon, typeName })
        return arr
      }, [])
    }
  },
  methods: {
    handleTypeClick (item) {
      const typeBox = document.querySelector('.home_type_box')
      typeBox.scrollIntoView({ behavior: 'smooth' })
      VEBus.$emit('APP_SWIPE_TYPE_CLICK', item.id)
    },
    handlePointClick () {
      const pointTitle = document.querySelector('.else_for_show .title')
      // smooth 平滑
      pointTitle.scrollIntoView({ behavior: 'smooth' })
    },
    handleBannerClick (item) {
      console.log(item)
      if (!item.link) return
      window.open(item.link)
    },
    handleShowQrcode () {
      this.showQrcode = !this.showQrcode
      if (this.showQrcode) {
        eventOnce(document.body, 'click', () => {
          this.showQrcode = false
        })
      }
    },
    async fetchBannerList () {
      try {
        let bannerList = await getBannerList()
        this.bannerList = bannerList.map(item => {
          let { id, bannerUrl: link, bannerPath: img } = item
          return { id, link, img }
        })
      } catch (e) {
        console.log(e)
      }
    }
  },
  async mounted () {
    this.$_app_Loading.show()
    await Promise.all([this.fetchBannerList(), this.$store.dispatch('getAllRootType')])
    this.showType = true
    this.$_app_Loading.hide()
  }
}
</script>

<style lang="scss">
.home_container {
  min-width: 1200px;
  .home_swiper_box {
    background-color: #fff;
    position: relative;
    padding-top: 65px;
  }
  .home_swiper {
    height: 400px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .home_swiper_bottom {
    padding: 30px 0;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .per {
      flex: 0 0 auto;
      &:nth-child(1) {
        div {
          background-image: url('../assets/img/swipeBottom1.png');
        }
      }
      &:nth-child(2) {
        div {
          background-image: url('../assets/img/swipeBottom2.png');
        }
      }
      &:nth-child(3) {
        div {
          background-image: url('../assets/img/swipeBottom3.png');
        }
      }
      &:nth-child(4) {
        div {
          background-image: url('../assets/img/swipeBottom4.png');
        }
      }
      div {
        width: 50px;
        height: 60px;
        margin: 0 auto 15px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
      }
      p {
        font-size: 20px;
        text-align: center;
      }
    }
  }
  .home_swiper_rootType {
    position: absolute;
    z-index: 5;
    top: 20px;
    left: 50%;
    width: 220px;
    transform: translateX(-600px);
    color: #fff;
    .rootType_title {
      background-color: $theme-green;
      font-size: 24px;
      text-align: center;
      height: 45px;
      line-height: 45px;
      letter-spacing: 5px;
    }
    .rootType_box {
      height: 400px;
      background-color: #555440;
      display: flex;
      flex-flow: column;
      justify-content: space-around;
      padding: 15px;
    }
  }
  .rootType_box .per {
    cursor: pointer;
    font-size: 20px;
    // text-align: center;
    padding: 5px 0 5px 60px;

    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    @include text-ellipsis();
  }
  .home_swiper_menu {
    position: absolute;
    z-index: 5;
    top: 25px;
    left: 50%;
    transform: translateX(-380px);
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .per {
      padding-left: 50px;
      font-size: 16px;
      position: relative;
      &.hastri {
        padding-right: 15px;
        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 9px;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-top-color: $fc-main;
        }
      }
      &.hascursor {
        cursor: pointer;
      }
      .tooltip {
        position: absolute;
        padding: 1px 5px 1px 10px;
        letter-spacing: 5px;
        color: #fff;
        background-color: $theme-color;
        white-space: nowrap;
        text-align: center;
        border-radius: 2px;
        top: -25px;
        right: 0;
        font-size: 14px;
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 100%);
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-top-color: $theme-color;
        }
      }
    }
    .with_qrcode {
      .menu_qrcode_box {
        position: absolute;
        display: block;
        width: 200px;
        height: 200px;
        top: 40px;
        left: 0;
        z-index: 6;
        background-color: #fff;
        // transition: opacity 0.3s;
        padding: 10px;
        border-radius: 5px;
        background-image: url('../assets/img/qrcode.png');
        background-size: contain;
        background-repeat: no-repeat;
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 0;
          transform: translate(-50%, -100%);
          width: 0;
          height: 0;
          border: 15px solid transparent;
          border-bottom-color: #fff;
        }
      }
    }
  }
}
</style>
