<template>
  <div class="home_info_box">
    <div class="app_contentBox">
      <div class="info_title">
        广东地区专享服务
      </div>
      <div class="info_top">
        <div class="info_top_l">
          <div class="l_left">
            <p>您身边的爱回收服务站</p>
          </div>
          <div class="l_right">
            <p class="title">全部门店 (3)</p>
            <div :key="index"
                 class="per_shop_info"
                 v-for="(item,index) of shopList">
              <div>{{item.title}}</div>
              <div>{{item.address}}</div>
              <div><a class="contact"
                   :href="'tel:'+item.tel"></a>
                <div class="map_btn"
                     @click="handleMapClick(item)"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="info_top_r">
          <p>方便快捷的快递服务</p>
          <p>广东省地区的用户<br />可享受免费寄样顺丰到付服务</p>
          <p class="app_theme_color">联系客服 看样品 免费寄</p>
          <div class="info_top_r_imgs">
            <div>
              <span>专业</span>
            </div>
            <div>
              <span>放心</span>
            </div>
            <div>
              <span>高效</span>
            </div>
          </div>
        </div>
      </div>
      <div class="info_title">
        网萌回收<span class="app_theme_color">1000+</span>工厂选择了我们
      </div>
      <div class="buyer_box">
        <div v-for="(item,index) of buyerList"
             :key="index"
             class="per_buyer">
          <div class="buyer_top">
            <span>{{item.phone}}</span>
          </div>
          <p v-html="item.text"></p>
          <div class="buyer_bottom">
            <p>通过<span>网萌回收</span>方式</p>
            <p>{{item.result}}</p>
          </div>
        </div>
      </div>
      <div class="else_for_show">
        <div class="title">直击行业痛点</div>
        <div class="flex_box">
          <div class="else_point">
            <p>保护隐私安全</p>
            <span>专业团队操作</span>
          </div>
          <div class="else_point">
            <p>专享上门服务</p>
            <span>上门/门店/快递回收</span>
          </div>
          <div class="else_point">
            <p>价格公道</p>
            <span>评估属实 绝不压价</span>
          </div>
          <div class="else_point">
            <p>快速打款</p>
            <span>交易成功 立即打款</span>
          </div>
        </div>
        <div class="title">网萌回收承诺</div>
        <div class="flex_box">
          <div class="else_promise">
            <span>互联网+</span>
            <p>商务证书 公安备案</p>
          </div>
          <div class="else_promise">
            <span>回收资质</span>
            <p>线上网络 线下渠道</p>
          </div>
        </div>
      </div>
    </div>
    <van-dialog :overlay="true"
                :close-on-click-overlay="true"
                v-model="showDialog"
                class="map_dialog">
      <van-icon class="close_map_btn"
                name="close"
                @click.stop="showDialog = false" />
      <div id="allmap"></div>
    </van-dialog>
  </div>
</template>

<script>
import { Icon } from 'vant';
const BMap = window.BMap
export default {
  name: 'app-home-info',
  components: {
    [Icon.name]: Icon
  },
  data () {
    return {
      shopList: [
        {
          title: '东莞东坑镇网萌回收站',
          address: '东莞市东坑镇初坑村大地工业区路尾',
          tel: '13822131711',
          latitude: 113.9277325728,
          longitude: 22.9878655112,
        },
        {
          title: '东莞大朗网萌回收站',
          address: '东莞市大朗镇高英村116-118号',
          tel: '18565563420',
          latitude: 113.9255366613,
          longitude: 22.9752200833,
        },
        {
          title: '东莞常平网萌回收站',
          address: '东莞市常平镇松柏塘松横路50号',
          tel: '17682342688',
          latitude: 114.0081086600,
          longitude: 23.0087009152,
        },
      ],
      buyerList: [
        {
          text: '"专业,放心,高效,<br/>比传统的废品收购站更具优势的是他们以互联网回收的模式,<br/>进行线上报价,线下回收"',
          phone: '178****8830',
          result: '成功交易了5台旧机器'
        },
        {
          text: '"有大型的场地,交易结算方式<br/>可选择,年轻团队有想法<br/>网上有实时报价,<br/>参考价回收价格合理"',
          phone: '138****4225',
          result: '成功交易了12吨铝边料'
        },
        {
          text: '"最新环保查的严,以前合作的废品站都没有正规的合同手续,<br/>找到了网萌,签了合同,<br/>合作非常愉快"',
          phone: '139****5231',
          result: '成功交易了30吨废钢筋'
        },
        {
          text: '"报价速度挺快的，客服说拍图片直接报价，比起传统的现场报价<br/>来说我更喜欢这种模式。<br/>高速，便捷，省事。"',
          phone: '137****4268',
          result: '成功交易了5吨磷铜边料'
        },
        {
          text: '"电子料这一块还是很专业的，以前卖给附近的废品站，大家都不懂价格，便宜处理了。萌收给人的感觉是专业，价格高，值得信赖。"',
          phone: '133****2823',
          result: '成功交易了2吨电子废弃物'
        },
      ],
      showDialog: false,
      mapInstance: {}
    }
  },
  methods: {
    handleMapClick (item) {
      this.showDialog = true
      this.$nextTick(() => {
        this.initMap(item)
      })
    },
    initMap (item) {
      console.log(item)
      // let that = this
      const mapInfo = item
      // 创建地图实例
      // var map = this.mapInstance
      var map = new BMap.Map('allmap')
      map.clearOverlays()
      // 创建点坐标
      var pt = new BMap.Point(mapInfo.latitude, mapInfo.longitude);
      map.centerAndZoom(pt, 15);
      map.addControl(new BMap.NavigationControl());
      var marker2 = new BMap.Marker(pt); // 创建标注
      marker2.addEventListener('click', () => {
        this.showInfo(marker2, { name: mapInfo.title, tel: mapInfo.tel, address: mapInfo.address });
        console.log(marker2.getPosition(), item)
      })
      map.addOverlay(marker2);

      this.showInfo(marker2, { name: mapInfo.title, tel: mapInfo.tel, address: mapInfo.address });
    },
    showInfo (thisMarker, p) {
      // 获取点的信息
      var sContent =
        '<ul style="margin:0 0 5px 0;padding:0.2em 0">' + '<li style="line-height: 26px;font-size: 15px;">' + '<span style="width: 60px;display: inline-block;">联系人：</span>' + p.name + '</li>' + '<li style="line-height: 26px;font-size: 15px;">' + '<span style="width: 50px;display: inline-block;">电话：</span>' + p.tel + '</li>' + '<li style="line-height: 26px;font-size: 15px;"><span style="width: 50px;display: inline-block;">地址：</span>' + p.address + '</li>' + '</ul>';
      var infoWindow = new BMap.InfoWindow(sContent); // 创建信息窗口对象
      thisMarker.openInfoWindow(infoWindow); // 图片加载完后重绘infoWindow
    },
  },
  mounted () {
    // this.mapInstance = new BMap.Map('allmap')
  }
}
</script>

<style lang="scss">
.home_info_box {
  padding-top: 50px;
  background-color: #fff;
  .info_title {
    height: 70px;
    line-height: 75px;
    font-size: 22px;
    .app_theme_color {
      font-size: 16px;
    }
  }
  .info_top {
    @include clearfix();
    .info_top_l {
      width: 825px;
      float: left;
      padding: 20px;
      font-size: 18px;
      @include clearfix();
    }
    .info_top_r {
      float: right;
      width: 350px;
      padding: 35px 20px;
      display: flex;
      flex-flow: column;
      font-size: 16px;
      justify-content: space-between;
    }
    .info_top_l,
    .info_top_r {
      height: 370px;
      border: 1px solid #f1f1f1;
    }
  }
  .l_left {
    text-align: center;
    float: left;
    height: 100%;
    width: 330px;
    margin-right: 50px;
    background-position: center bottom;
    background-size: 100% 300px;
    background-repeat: no-repeat;
    background-image: url('../assets/img/info_l.png');
  }
  .l_right {
    font-size: 16px;
    text-align: left;
    float: left;
    height: 100%;
    .title {
      color: $theme-color;
    }
    .per_shop_info {
      .contact,
      .map_btn {
        cursor: pointer;
        display: inline-block;
        width: 100px;
        height: 25px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }
      .contact {
        background-image: url('../assets/img/shop_1.png');
      }
      .map_btn {
        margin-left: 10px;
        background-image: url('../assets/img/shop_2.png');
      }

      & > div {
        padding: 5px 0;
      }
    }
  }
  .info_top_r_imgs {
    padding: 10px 0;
    display: flex;
    div {
      padding-top: 55px;
      width: 33.3%;
      text-align: center;
      font-size: 14px;
      background-repeat: no-repeat;
      background-size: 50px 50px;
      background-position: center top;
      &:nth-child(1) {
        background-image: url('../assets/img/info_r1.png');
      }
      &:nth-child(2) {
        background-image: url('../assets/img/info_r2.png');
      }
      &:nth-child(3) {
        background-image: url('../assets/img/info_r3.png');
      }
    }
  }
  .buyer_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 55px;
    .per_buyer {
      box-shadow: 0 0 10px 3px #f2f2f2;
      width: 230px;
      flex: 0 0 auto;
      text-align: center;
      transition: all 0.3s;
      &:hover {
        box-shadow: 0 0 10px 5px #ccc;
      }
      .buyer_top {
        background-repeat: no-repeat;
        background-size: 30px 27px;
        background-position: 30px 15px;
        background-image: url('../assets/img/user.png');
        font-size: 16px;
        padding: 10px 30px 20px 70px;
        span {
          display: inline-block;
          padding: 0 0 20px 0;
          background-image: url('../assets/img/stars.png');
          background-position: 5px 25px;
          background-repeat: no-repeat;
        }
      }
      & > p {
        padding: 0 10px 25px;
        line-height: 35px;
      }
      .buyer_bottom {
        border-top: 1px solid #ccc;
        padding-bottom: 20px;
        & p:first-child {
          padding: 15px 0;
        }
        span {
          color: $theme-green;
        }
      }
    }
  }
  .else_for_show {
    padding-bottom: 50px;
    .title {
      font-size: 32px;
      text-align: center;
      padding-bottom: 60px;
    }
    .flex_box {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .else_point {
      background-repeat: no-repeat;
      background-size: 140px 140px;
      background-position: center top;
      font-size: 26px;
      text-align: center;
      padding: 140px 0;
      width: 240px;
      flex: 0 0 auto;
      p {
        padding: 20px 0 15px;
      }
      span {
        color: $fc-sub;
      }
      &:nth-child(1) {
        background-image: url('../assets/img/else_1.png');
      }
      &:nth-child(2) {
        background-image: url('../assets/img/else_2.png');
      }
      &:nth-child(3) {
        background-image: url('../assets/img/else_3.png');
      }
      &:nth-child(4) {
        background-image: url('../assets/img/else_4.png');
      }
    }
    .else_promise {
      width: 240px;
      flex: 0 0 auto;
      span {
        color: $fc-sub;
        font-size: 32px;
        display: inline-block;
        padding: 20px 20px 20px 90px;
        background-repeat: no-repeat;
        background-size: 80px 80px;
        background-position: left center;
      }
      &:nth-child(1) {
        span {
          background-image: url('../assets/img/else_5.png');
        }
      }
      &:nth-child(2) {
        span {
          background-image: url('../assets/img/else_6.png');
        }
      }
      p {
        text-align: center;
        font-size: 26px;
        padding-top: 10px;
      }
    }
  }
  .map_dialog {
    width: 800px;
    height: 500px;
  }
  .close_map_btn {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    font-size: 20px;
    color: #fff;
    font-weight: 800;
    text-shadow: 0 0 5px #000;
  }
  #allmap {
    width: 800px;
    height: 500px;
    margin: 0 auto;
  }
}
</style>
