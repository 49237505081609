import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vutil from './util/Vutil'
Vue.config.productionTip = false

Vue.use(Vutil)

router.afterEach((to, from) => {
  const scroller = document.body.querySelector('.app_container')
  if (scroller) {
    scroller.scrollTop = 0
  }
})
export default new Vue({
  router,
  store,
  render: h => h(App)
})
