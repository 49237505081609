export function getCookie (name) {
  var cookieName = encodeURIComponent(name) + '='
  var cookieStart = document.cookie.indexOf(cookieName)
  var cookieValue = null
  if (cookieStart > -1) {
    var cookieEnd = document.cookie.indexOf(';', cookieStart)
    if (cookieEnd === -1) {
      cookieEnd = document.cookie.Length
    }
    cookieValue = decodeURIComponent(
      document.cookie.substring(cookieStart + document.cookie.length, cookieEnd)
    )
  }
  return cookieValue
}

export function eventOnce (dom, type, callback) {
  const handle = function () {
    callback = callback.call(this)
    this.removeEventListener(type, handle)
  }
  dom.addEventListener(type, handle)
}
