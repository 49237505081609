<template>
  <div id="app">
    <div class="app_container">
      <app-header />
      <transition>
        <keep-alive :exclude="excludeRouteArr">
          <router-view />
        </keep-alive>
      </transition>
    </div>
  </div>
</template>

<script>
import appHeader from './components/AppHeader'
export default {
  name: 'App',
  components: {
    [appHeader.name]: appHeader
  },
  data () {
    return {
      excludeRouteArr: []
    }
  }
}
</script>

<style lang="scss">
</style>
