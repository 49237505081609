<template>
  <div class="home_footer">
    <div class="app_contentBox">
      <div class="title">联系我们</div>
      <div class="service_list">
        <div class="per">
          饭饭
          <div></div>
        </div>
        <div class="per">
          娟娟
          <div></div>
        </div>
        <div class="per">
          瑶瑶
          <div></div>
        </div>
        <div class="per">
          菊菊
          <div></div>
        </div>
        <div class="per">
          雯雯
          <div></div>
        </div>
      </div>
      <div class="sub_title">
        关注公众号 获取最新报价
      </div>
      <img class="qrcode"
           src="../assets/img/newqrcode.png">
      <p>免费掌握废品最新报价</p>
      <a class="service_btn"
         :href="`tel:${$_app_phoneNum}`"></a>
         <a target="_blank" href="http://beian.miit.gov.cn"><p style="margin-top: 30px;">粤ICP备2023121235号</p></a>
         <a target="_blank" href="http://beian.miit.gov.cn"><p style="margin-top: 30px;">粤ICP备2023121235号</p></a>
         <a target="_blank" href="http://beian.miit.gov.cn"><p style="margin-top: 30px;">粤ICP备2023121235号</p></a>
    </div>

  </div>
</template>

<script>
export default {
  name: 'app-home-footer'
}
</script>

<style lang="scss">
.home_footer {
  background-color: #f6f7fb;
  padding: 140px 0;
  .title {
    font-size: 32px;
    text-align: center;
  }
  .service_list {
    padding-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 80px;
    .per {
      cursor: pointer;
      padding: 20px 35px 20px 75px;
      font-size: 22px;
      background-repeat: no-repeat;
      background-size: 65px 65px;
      background-position: left center;
      position: relative;
      &:nth-child(1) {
        background-image: url('../assets/img/service_1.png');
        div {
          background-image: url('../assets/img/qr_fan.jpg');
        }
      }
      &:nth-child(2) {
        background-image: url('../assets/img/service_2.png');
        div {
          background-image: url('../assets/img/qr_juan.jpg');
        }
      }
      &:nth-child(3) {
        background-image: url('../assets/img/service_3.png');
        div {
          background-image: url('../assets/img/qr_yao1.jpg');
        }
      }
      &:nth-child(4) {
        background-image: url('../assets/img/service_4.png');
        div {
          background-image: url('../assets/img/qr_ju1.jpg');
        }
      }
      &:nth-child(5) {
        background-image: url('../assets/img/service_5.png');
        div {
          background-image: url('../assets/img/qr_wen.jpg');
        }
      }
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 25px;
        height: 25px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('../assets/img/serviceQR.png');
      }

      &:hover {
        & div {
          display: block;
        }
      }

      div {
        display: none;
        position: absolute;
        bottom: 110%;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        width: 200px;
        height: 200px;
        background-repeat: no-repeat;
        background-size: contain;
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: -38px;
          width: 0;
          height: 0;
          transform: translateX(-50%);
          border: 20px solid transparent;
          border-top-color: #fff;
        }
      }
    }
  }
  .sub_title {
    font-size: 24px;
    text-align: center;
  }
  .qrcode {
    display: block;
    margin: 30px auto 50px;
    width: 120px;
    height: 120px;
  }
  p {
    font-size: 18px;
    text-align: center;
  }
  .service_btn {
    display: block;
    margin: 15px auto 0;
    width: 205px;
    height: 60px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../assets/img/service_btn.png');
  }
}
</style>
