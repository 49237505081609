import Vue from 'vue'
import Vuex from 'vuex'
import * as Api from '@/api'
import Cachedata from './model/CacheData'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    rootTypeList: {
      update: false,
      list: []
    },
    app_search_keyword: '',
    allTypeCacheData: new Cachedata({})
  },
  getters: {
    allTypeList (state) {
      return state.allTypeCacheData.data
    }
  },
  mutations: {
    UPDATE_ROOTTYPELIST (state, list = []) {
      state.rootTypeList.update = true
      state.rootTypeList.list = list
    },
    UPDATE_KEYWORD (state, str) {
      state.app_search_keyword = str
    },
    UPDATA_ALLTYPELIST (state, list = []) {
      // change state in mutation
      state.allTypeCacheData.updateCache(list)
    }
  },
  actions: {
    async getAllRootType ({ commit, state }, params) {
      try {
        let list = await Api.getAllType()
        commit(
          'UPDATE_ROOTTYPELIST',
          list.map(type => {
            let { id, typeName, icon } = type
            return { id, typeName, icon }
          }) || []
        )
      } catch (e) {
        console.log(e)
      }
    },
    async getSecTypeAll ({ commit, state }) {
      try {
        const { allTypeCacheData } = state
        if (allTypeCacheData.needUpdate) {
          let list = await Api.getSecTypeAll()
          commit('UPDATA_ALLTYPELIST', list)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
})
