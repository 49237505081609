<template>
  <div class="app_loading_box"
       :class="[mountable?'app_loading_justcenter':'']">
    <van-loading color="white"
                 size="50px" />
  </div>
</template>

<script>
import { loading } from 'vant'
export default {
  name: 'ui-app-loading',
  data () {
    return {
      mountable: false
    }
  },
  components: {
    [loading.name]: loading
  }
}
</script>

<style lang="scss">
.app_loading_box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  &.app_loading_justcenter {
    display: block;
    position: absolute;
    z-index: 2000;
    @include inCenter();
    right: unset;
    bottom: unset;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 5px;
  }

  .van-loading--white circle {
    stroke: $theme-color;
  }
}
</style>
