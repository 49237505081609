<template>
  <div class="app_header">
    <div class="app_contentBox">
      <div class="app_header_top">
      </div>
      <div class="app_header_mid">
        <div class="app_header_logo"
             @click="$router.push({name:'home'})"></div>
        <div class="app_header_logophone"></div>
        <div class="app_header_search">
          <input placeholder="请输入回收产品"
                 type="search"
                 v-model.trim="keyword"
                 @search="handleSearch">
          <button @click="handleSearch">
            <van-icon class="search_btn_i"
                      name="search" /></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from 'vant';
export default {
  name: 'AppHeader',
  components: {
    [Icon.name]: Icon
  },
  data () {
    return {
      keyword: ''
    }
  },
  methods: {
    updateKeyword (keyword) {
      this.keyword = keyword
    },
    handleSearch () {
      if (this.keyword === '') return
      this.$store.commit('UPDATE_KEYWORD', this.keyword)
      this.$router.push({ name: 'search' })
    }
  }
}
</script>

<style lang="scss">
.app_header {
  background-color: #fff;
  padding-bottom: 5px;
  min-width: 1200px;
  .app_header_top {
    color: $fc-sub;
    height: 50px;
    background-image: url('../assets/img/header_top.png');
    background-size: 1130px 24px;
    background-position: right center;
    background-repeat: no-repeat;
  }
  .app_header_mid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }
  .app_header_logo {
    cursor: pointer;
    width: 188px;
    height: 90%;
    background-image: url('../assets/img/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left top;
  }
  .app_header_logophone {
    width: 190px;
    height: 47px;
    background-image: url('../assets/img/header_phone.png');
    background-size: 191px 47px;
    background-repeat: no-repeat;
    margin-left: 40px;
    margin-right: auto;
    background-position: left top;
  }
  .app_header_search {
    height: 100%;
    width: 330px;
    display: flex;
    align-items: center;
    input {
      display: inline-block;
      flex: 1;
      height: 50px;
      border: 2px solid $theme-green;
      border-radius: 0;
      padding: 10px;
    }
    button {
      display: inline-block;
      flex: 0 0 auto;
      width: 50px;
      height: 100%;
      background-color: $theme-green;
      border: none;
    }
    .search_btn_i {
      font-weight: 800;
      font-size: 25px;
      line-height: 49px;
    }
  }
}
</style>
